<template>
  <div>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle + ' ' + selectedSaison.label"
        route_name="gestion_des_licences__dashboard"
      />
    </v-row>

    <userListSearch
      :context="{
        ID_Utilisateur: currentUserId,
        ID_UtilisateurDemande: currentUserId,
        ID_Structure: currentStructureId,
        ID_Saison: selectedSaison.id,
        hasCodeFederal: hasCodeFederal,
      }"
      :actions="getActions"
      show_select
    />
  </div>
</template>

<script>
import userListSearch from "@/components/User/List/User__ListSearch.vue";
import SubBar from "@/components/Common/Common__SubBar.vue";
import { mapGetters } from "vuex";

export default {
  components: { SubBar, userListSearch },
  name: "GestionDesLicences__RenouvelerLicencesEnSerie",

  data: () => ({
    subBarTitle: "Renouveler licences en série",
    headers: [
      { text: "Structure", value: "NomStructure" },
      { text: "Numéro de licence", value: "LicenceNumero" },
      { text: "Prénom", value: "CT_Prenom" },
      { text: "Nom", value: "CT_Nom" },
      { text: "Licence type", value: "ID_LicenceType", sortable: true },
      // { text: "Saison", value: "ID_LicenceSaison" },

      // { text: 'Role Federal', value: 'EST_RoleFederal' },
      // { text: 'Role structure', value: 'ID_Role' },

      // { text: 'Statut licence', value: 'ID_EtapeLicence' },
      { text: "Actions", value: "actions" },
    ],
    mounted: false,
    saisonSelected: null,
  }),

  created() {
    this.mounted = true;
  },

  computed: {
    ...mapGetters("user", ["currentUserId"]),
    ...mapGetters("structure", ["currentStructureId", "hasCodeFederal"]),
    ...mapGetters("saisons", ["selectedSaison", "saisons"]),

    getActions() {
      let actions = "show|editLicence|editLicenceMultiple";
      return actions;
    },
  },
};
</script>
