<template>
  <section class="licences-csv px-4">
    <section class="header d-flex justify-space-around p-4">
      <span>Aide : </span>
      <a
        target="blank"
        :href="importModeEmploiUrl"
        >Mode d'emploi</a
      >
      <span>|</span>
      <a
        target="blank"
        :href="importTrameUrl"
        >Trame d'un fichier CSV type</a
      >
      <span>|</span>
      <a
        target="blank"
        :href="importDetailsChampsUrl"
        >Détails des champs</a
      >
      <span>|</span>
      <a
        target="blank"
        :href="importExempleUrl"
        >Exemple de fichier CSV</a
      >
    </section>
    <section class="d-flex">
      <v-file-input
        class="w-50 mt-4 mr-4"
        style="margin-left: 42px;"
        placeholder="Sélectionnez un fichier CSV"
        v-model="selectedFile"
        :disabled="!hasCodeFederal"
        dense
        outlined
        show-size
        @change="readCSV"
      ></v-file-input>

      <div class="w-50 mt-4">
        <v-alert
          v-if="loading"
          type="info"
          text
        >
          <strong>Import en cours</strong>
          En fonction du nombre d’adhérent à importer, l’action peut être plus ou moins longue. Vous serez informé du résultat ici.
        </v-alert>
        <v-alert
          v-if="isErrors && csvErrors.length"
          type="error"
          text
          icon="info"
        >
          Le fichier comporte {{ csvErrors.length }} ligne{{ csvErrors.length > 1 ? 's' : '' }} en erreur. Veuillez corriger le fichier et le réimporter
        </v-alert>
        <v-alert
          v-if="isErrors && !csvErrors.length"
          type="error"
          text
          icon="info"
        >
          {{ isErrors }}
        </v-alert>
        <v-alert
          v-if="userCSVList.length"
          type="success"
          text
          icon="info"
        >
          <div><strong>Succès</strong></div>
          <div>{{ userCSVList.length }} adhérent{{ userCSVList.length > 1 ? 's ont été importés ' : ' a été importé' }}</div>
        </v-alert>
      </div>
    </section>

    <div v-if="firstLoad">
      <p>Veuillez patienter...</p>
      <v-skeleton-loader :loading="loading" type="table"></v-skeleton-loader>
    </div>
    <v-data-table
      :headers="errorHeaders"
      :items="csvErrors"
      item-key="line"
      hide-default-footer
      disable-pagination
      disable-sort
      v-if="csvErrors.length"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{item.line}}</td>
          <td>
            <div :key="index" v-for="(msg, index) in item.message">
              <div>
                {{index}}
              </div>
              <ul>
                <li :key="i" v-for="(value, i) in msg">
                  {{value}}
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import { getSeasonDocuments } from "../../plugins/seasonService";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "GestionDesLicences__SaisirLesLicencesViaCSV",
  data: () => ({
    selectedFile: null,
    userCSVList: [],
    errorHeaders: [
      { text: "Ligne" },
      { text: "Message" },
    ],

    isErrors: false,
    isFormatError: false,
    csvErrors: false,
    allowedExt: ["csv", "xlsx"],
    loading: false,
    firstLoad: false,
    importModeEmploiUrl: '',
    importTrameUrl: '',
    importDetailsChampsUrl: '',
    importExempleUrl: '',
    selectedSeason: undefined,
    seasonDocuments: [],
  }),

  async created() {
    if (this.$route.query.season) {
      this.selectedSeason = parseInt(this.$route.query.season, 10);

      let saison = this.saisons.find(saison => saison.id === this.selectedSeason && (saison.preOpen || saison.active));

      if (!saison) {
        this.selectedSeason = this.currentSaison.id;
      }
    } else {
      this.selectedSeason = this.currentSaison.id;
    }

    const affiliation = await this.getStructureAffiliation({
      ID_Structure: this.currentStructureId,
      ID_Saison: this.selectedSeason,
    });

    if (!affiliation || affiliation.ID_EtapeAffiliation !== 3) {
      window.location.href = `${process.env.VUE_APP_FRONT_NEXT_URL}/acces-restreint?structureId=${this.currentStructureId}?appMode=${localStorage.getItem("ffme_user_mode")}`;
    }

    this.seasonDocuments = await getSeasonDocuments(this.selectedSeason);
    const importModeEmploi = this.seasonDocuments?.documents?.find((document) => document.type === 'csv_import_guide_template');
    const importTrame = this.seasonDocuments?.documents?.find((document) => document.type === 'csv_import_example_template');
    const importDetailsChamps = this.seasonDocuments?.documents?.find((document) => document.type === 'csv_import_fields_details_template');
    const importExemple = this.seasonDocuments?.documents?.find((document) => document.type === 'csv_import_example_template');

    this.importModeEmploiUrl = `${process.env.VUE_APP_FFME_BACK_URL}/api/files/season_document/${importModeEmploi?.id ?? ''}`;
    this.importTrameUrl = `${process.env.VUE_APP_FFME_BACK_URL}/api/files/season_document/${importTrame?.id ?? ''}`;
    this.importDetailsChampsUrl = `${process.env.VUE_APP_FFME_BACK_URL}/api/files/season_document/${importDetailsChamps?.id ?? ''}`;
    this.importExempleUrl = `${process.env.VUE_APP_FFME_BACK_URL}/api/files/season_document/${importExemple?.id ?? ''}`;
  },

  computed: {
    ...mapGetters("saisons", ["currentSaison", 'saisons']),
    ...mapGetters("structure", [
      "currentStructureId",
      "hasCodeFederal",
    ]),
  },
  methods: {
    ...mapActions("structure", ["getStructureAffiliation"]),
    async readCSV() {
      this.loading = true;
      this.firstLoad = true;
      this.userCSVList = [];
      this.csvErrors = [];
      if (this.selectedFile) {
        let ext = this.selectedFile.name.split(".")[1];
        if (!this.allowedExt.includes(ext)) {
          this.isErrors = 'Le format du fichier n\'est pas valide (csv ou xlsx)';
          this.isFormatError = true;
          this.loading = false;
          this.firstLoad = false;
          return;
        }
        const payload = new FormData();
        payload.append("file", this.selectedFile);
        payload.append("action", "IMPORT_MEMBERS");
        payload.append("structure_id", this.currentStructureId);
        payload.append("season", this.selectedSeason);

        try {
          const response = await this.axios.post(
            process.env.VUE_APP_FFME_BACK_LARAVEL_MS_MEMBER_URL + '/imports',
            payload,
            {
              headers:{
                Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME),
              },
            }
            );

            this.isErrors = false;
            console.log(response.data);
            this.userCSVList = response.data;
            this.csvErrors = [];
          } catch (error) {
            const errors = [];
            if (error.response.status === 500 ){
              this.isErrors = "Une erreur inattendue est survenue lors de l'import du fichier."
            } else if (error.response.data?.error) {
              this.isErrors = error.response.data.error;
            } else {
              Object.keys(error.response.data).forEach((key) => {
                errors.push({line: key, message: error.response.data[key]});
              });
              this.csvErrors = errors;
              this.isErrors = true;
            }
            this.userCSVList = [];
          } finally {
            this.isFormatError = false;
            this.loading = false;
            this.firstLoad = false;
          }
      } else {
        this.userCSVList = [];
        this.loading = false;
        this.isErrors = false;
        this.isFormatError = false;
        this.firstLoad = false;
      }
    },
  },
};
</script>

<style lang="scss">
.licences-csv {
  .v-data-table tbody tr td {
    border-right: 2px solid lightgrey;
  }
  .error-red {
    background: #ff1515 !important;
  }
  .error-orange {
    background: #ff6600 !important;
  }
  .error-light-orange {
    background: #ffc184 !important;
  }
  .error-yellow {
    background: #ffff82 !important;
  }
  .error-purple {
    background: #7272b8 !important;
  }
  .none {
    background: white !important;
  }
  .legend {
    div {
      width: 40px;
      height: 20px;
    }
    p {
      margin: 0;
    }
  }
}
</style>
