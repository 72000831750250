<template>
  <div>
    <v-card-text v-if="mounted">
      <ValidateForm @validated="submit" :showFieldObl="false">
        <v-row>
          <!-- <v-col cols="12">
            <v-checkbox
              style="width: 100%"
              v-model="confirm"
              label="J'accepte balabl De l'impression au bureau vers le Web. Aldus Corporation, qui a plus tard fusionné avec Adobe Systems, a contribué à amener Lorem Ipsum à l'ère de l'information avec son logiciel phare 1985  "
            ></v-checkbox>
          </v-col> -->
          <v-col>
            <v-btn :disabled="!hasCodeFederal" :loading="loading" type="submit" class="btn btn-primary">Valider
              l'affiliation</v-btn>
          </v-col>
        </v-row>
      </ValidateForm>
    </v-card-text>
    <PrintFacture ref="facture" :print="print"></PrintFacture>
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form";

import { mapActions, mapGetters } from "vuex";
import { send_email, trimAndCapitalizeFirstLetter, trimAndUppercaseAllLetters } from "@/plugins/utils";
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";
import router from "@/router";
import PrintFacture from "@/components/Structure/Finance/PrintFacture.vue";
import { formatFluxFromAffiliation, insertFlux } from "@/plugins/fluxService";
import { addStructureToCampaign } from "@/plugins/campaignService";
import {
  getStructuresFromZipcode,
  getDepartement,
  insertStructureDocumentFromDocDocumentUrl,
} from "@/plugins/structureService";
import {
  getPrimoAffiIsExistingUser,
} from "@/plugins/userService";

export default {
  components: { ValidateForm, PrintFacture },

  data: () => ({
    confirm: false,
    loading: false,
    mounted: false,
    saisonID: null,
    documentsType: [],
    categoryTarifFamille: [],
    allFonctionType: [],
    print: {
      assurance: null,
      cm: null,
      user: null,
      licence: null,
      complementaire: null,
      options: [],
      ID_Saison: null,
    },
  }),

  async created() {
    await this.setData();
    console.log("this.item -----", this.item);
    this.saisonID = this.item.ID_PremiereSaison;
    this.mounted = true;
  },

  computed: {
    ...mapGetters("structure", ["currentStructure", "hasCodeFederal"]),
    ...mapGetters("user", ["currentUser", "currentUserId"]),
    ...mapGetters("saisons", ["getSaison", "nextSaison"]),

    UTI_Utilisateur() {
      if (!this.item.UTI_Utilisateur) return false;
      return JSON.parse(this.item.UTI_Utilisateur);
    },
    ADR_Adresse() {
      if (!this.item.ADR_Adresse) return false;
      return JSON.parse(this.item.ADR_Adresse);
    },

    STR_Affiliation() {
      if (!this.item.STR_Affiliation) return false;
      return JSON.parse(this.item.STR_Affiliation);
    },

    DOC_Documents() {
      if (!this.item.DOC_DocumentsTemp) return false;
      return this.item.DOC_DocumentsTemp;
    },

    DOC_Status() {
      let id_typeDoc = this.documentsType.find(
        (doc) => doc.SlugTypeDocument == "status"
      ).id;
      return this.DOC_Documents.find(
        (doc) => doc.ID_DocumentType == id_typeDoc
      );
    },

    DOC_Rib() {
      let id_typeDoc = this.documentsType.find(
        (doc) => doc.SlugTypeDocument == "rib"
      ).id;
      return this.DOC_Documents.find(
        (doc) => doc.ID_DocumentType == id_typeDoc
      );
    },
    DOC_Mandat() {
      let id_typeDoc = this.documentsType.find(
        (doc) => doc.SlugTypeDocument == "mandat_représentant_légal"
      ).id;
      return this.DOC_Documents.find(
        (doc) => doc.ID_DocumentType == id_typeDoc
      );
    },
    DOC_Sepa() {
      let id_typeDoc = this.documentsType.find(
        (doc) => doc.SlugTypeDocument == "mandat_sepa"
      ).id;
      return this.DOC_Documents.find(
        (doc) => doc.ID_DocumentType == id_typeDoc
      );
    },
    // DOC_Mandat() {
    //   let id_typeDoc = this.documentsType.find(
    //     (doc) => doc.SlugTypeDocument == "recepisse_prefectoral_declaration"
    //   ).id;
    //   return this.DOC_Documents.find(
    //     (doc) => doc.ID_DocumentType == id_typeDoc
    //   );
    // },

    DOC_ParutionJO() {
      let id_typeDoc = this.documentsType.find(
        (doc) => doc.SlugTypeDocument == "parution_jo"
      ).id;
      return this.DOC_Documents.find(
        (doc) => doc.ID_DocumentType == id_typeDoc
      );
    },

    DOC_RecepissePrefectoralDeclaration() {
      let id_typeDoc = this.documentsType.find(
        (doc) => doc.SlugTypeDocument == "recepisse_prefectoral_declaration"
      ).id;
      return this.DOC_Documents.find(
        (doc) => doc.ID_DocumentType == id_typeDoc
      );
    },

    DOC_ReglementInterieur() {
      let id_typeDoc = this.documentsType.find(
        (doc) => doc.SlugTypeDocument == "reglement_interieur"
      ).id;
      return this.DOC_Documents.find(
        (doc) => doc.ID_DocumentType == id_typeDoc
      );
    },

    DOC_DiplomeEncadrant() {
      let id_typeDoc = this.documentsType.find(
        (doc) => doc.SlugTypeDocument == "diplome_encadrant"
      ).id;
      return this.DOC_Documents.find(
        (doc) => doc.ID_DocumentType == id_typeDoc
      );
    },

    DOC_CarteProfessionnelle() {
      let id_typeDoc = this.documentsType.find(
        (doc) => doc.SlugTypeDocument == "copie_cartes_professionnelles"
      ).id;
      return this.DOC_Documents.find(
        (doc) => doc.ID_DocumentType == id_typeDoc
      );
    },

    DOC_AttestationAssurance() {
      let id_typeDoc = this.documentsType.find(
        (doc) => doc.SlugTypeDocument == "attestation_assurance"
      ).id;
      return this.DOC_Documents.find(
        (doc) => doc.ID_DocumentType == id_typeDoc
      );
    },

    MKP_Tarifs() {
      if (!this.item.MKP_Tarifs) return false;
      return JSON.parse(this.item.MKP_Tarifs);
    },

    ACTI_ActivitePratiquees() {
      if (!this.item.ACTI_Activites) return false;
      return JSON.parse(this.item.ACTI_Activites);
    },
    MKP_Rib() {
      if (!this.item.MKP_Rib) return false;
      return JSON.parse(this.item.MKP_Rib);
    },

    SeasonId() {
      if (!this.item.STR_Affiliation) {
        return false
      }

      return JSON.parse(this.item.STR_Affiliation).ID_Premiere_Saison;
    },

    Season() {
      if (!this.item.STR_Affiliation) {
        return false;
      }

      const season = this.getSaison(parseInt(this.SeasonId, 10));

      return season;
    },
  },

  methods: {
    ...mapActions("utilisateurs", [
      "searchUserPrimoAffiliation",
      "createAuthUser",
      "createFluxFinancierDetail",
      "updateUser",
      "getUtilisateur"
    ]),
    ...mapActions("structure", [
      "insertStructure",
      "createNewStructure",
      "getLastStructure",
      "insertPrimoAffiliation",
      "updateAffiliationTarifSructure",
      "getTarifCotisationFFME",
      "updateStatusAffiliationTemp",
      "addUserToStructureOnPrimoAffi",
      "insertRib",
      "getDepartement",
      "getRegion",
    ]),
    ...mapActions("basics", [
      "getInfoGenerales",
      "getListTypesDocument",
      "getListTarifCategorie",
      "insertNewFamilleTarif",
      "getListTypeFonctions",
      "getListStructureType",
      "insertTarifs",
      "insertDocument",
    ]),

    async setData() {
      let type = await this.getListTypesDocument();

      this.documentsType = type.filter(
        (doc) =>
          doc.LIST_DocumentCategory?.SlugCategorieDocument === "documents_club"
      );

      this.categoryTarifFamille = await this.getListTarifCategorie();
      this.allFonctionType = await this.getListTypeFonctions();
      return;
    },

    async submit() {
      this.loading = true;

      // First, check if we have a user we those creditentials

      let user = {
        CT_Email: this.UTI_Utilisateur.correspondant
          ? this.UTI_Utilisateur.correspondant.CT_Email
          : this.UTI_Utilisateur.representantEmail,
        CT_Nom: this.UTI_Utilisateur.correspondant
          ? this.UTI_Utilisateur.correspondant.CT_Nom
          : this.UTI_Utilisateur.representantLastName,
        CT_Prenom: this.UTI_Utilisateur.correspondant
          ? this.UTI_Utilisateur.correspondant.CT_Prenom
          : this.UTI_Utilisateur.representantFirstName,
        LicenceNumero: this.UTI_Utilisateur.correspondant
          ? this.UTI_Utilisateur.correspondant.LicenceNumero
          : this.UTI_Utilisateur.representantLicenceNumero,
      };

      let ID_NomCorrespondant = await this.checkUser(user);

      // si le président, secrétaire et trésorier sont renseignés et qu'ils ont les mêmes informations que le correspondant (variable user), on met le ID_NomCorrespondant dans les ID_President, ID_Secretaire et ID_Tresorier
      let ID_President = {};
      let ID_Secretaire = {};
      let ID_Tresorier = {};

      if (this.UTI_Utilisateur.correspondant) {
        if (Object.keys(this.UTI_Utilisateur.president).length != 0) {
            ID_President = await this.checkUser(this.UTI_Utilisateur.president);
        }

        if (Object.keys(this.UTI_Utilisateur.secretaire).length != 0) {
            ID_Secretaire = await this.checkUser(this.UTI_Utilisateur.secretaire);
        }

        if (Object.keys(this.UTI_Utilisateur.tresorier).length != 0) {
            ID_Tresorier = await this.checkUser(this.UTI_Utilisateur.tresorier);
        }
      }
      //  console.log(ID_NomCorrespondant)
      // Then, prepare the structure data with adresse
      let structureID = await this.createStructure({
        ID_NomCorrespondant: ID_NomCorrespondant,
        ID_President: ID_President ? ID_President : null,
        ID_Secretaire: ID_Secretaire ? ID_Secretaire : null,
        ID_Tresorier: ID_Tresorier ? ID_Tresorier : null,
      });

      // // Then insert the affiliation with the documents for this saison
      let affiliationID = await this.insertAffiliation(structureID);

      // insert tarif
      let tarifs = {};
      if (this.MKP_Tarifs) tarifs = await this.insertTarifs(structureID);

      // Generate the flux
      let flux = await this.generateFlux(structureID);
      // NEW FLUX BACK FFME
      await insertFlux(formatFluxFromAffiliation({
        structureId: structureID,
        seasonId: this.SeasonId,
        createdAt: parseInt(this.SeasonId, 10) === parseInt(this.nextSaison.id, 10) ? new Date(this.Season.dateStart) : new Date(),
      }));

      // Then, update the status of the STR_AffiliationTemp
      await this.updateStatusAffiliationTemp({
        affiliation: { id: this.item.id, Status: 2 },
      });

      // Send email
      await this.sendEmail();

      // Add structure to campaign
      await addStructureToCampaign({
        structure: `/api/structure/${structureID}`,
        season: `/api/seasons/${this.SeasonId}`,
      })

      success_notification("La primo affiliation a bien été validée.");
      router.push({
        name: "gestion_des_affiliations__demandes_primo_affiliations",
      });

      this.loading = false;
    },

    async sendEmail() {
      let payloadEmail = {
        User: this.currentUserId.toString(), //ID_Utilisateur uuid A qui envoyer la confirmation de validation
        // ID_Structure: ID_Structure,
        Structure: this.item.STR_NomStructure,
        Email: this.UTI_Utilisateur.correspondant
          ? this.UTI_Utilisateur.correspondant.CT_Email
          : this.UTI_Utilisateur.representantEmail,
        Context: "successPrimoAffiliation",
        SlugModule: "gestion_des_animations_locale__demande_primo_affiliations",
        SeasonId: this.SeasonId.toString(),
      };
      console.log("payloadEmail-----", payloadEmail);

      try {
        // payload email
        let email = await send_email(payloadEmail);
        console.log("email-----", email);
        if (!email.ok) {
          error_notification("Une erreur est survenue lors de l'envoi du mail");
        }
      } catch (e) {
        console.log("error on email", e);
      }

      return;
    },

    // Cretae the structure and linked the user to it
    async createStructure(users) {
      // get last structureID
      let lastStructure = await this.getLastStructure();
      let activitePratique = this.makeActivitesPratiques();

      // if (this.currentStructure.ID_Structure != 1318) idFederation = 1318;

      const parentStructure = await getStructuresFromZipcode({
        zipcode: JSON.parse(this.item.ADR_Adresse).CodePostal,
      })

      let ctId;
      let ligueId;

      if (parentStructure.length === 3) {
        ctId = parentStructure[0];
        ligueId = parentStructure[1];
      } else if (parentStructure.length === 2) {
        ligueId = parentStructure[0];
      }

      const departement = await getDepartement(JSON.parse(this.item.ADR_Adresse).Departement.CodeDepartement);

      let payloadStructure = {
        NomStructure: this.item.STR_NomStructure,
        NomStructureComplement: this.item.NomStructureComplement,
        INFO_Presentation: this.item.STR_InfoPresentation,
        INFO_Email: this.UTI_Utilisateur.CT_Email,
        ID_NomCorrespondant: users.ID_NomCorrespondant,
        JUR_Siret: this.item.JUR_Siret,
        JUR_AgrementJeunesseSports: this.item.JUR_AgrementJeunesseSports,
        ID_TypeStructure: this.item.ID_TypeStructure,
        EST_CompteBloque: false,
        EST_Actif: 1,
        id: +lastStructure?.id + 1,
        ID_Federation: 1318,
        ID_ComiteTerritorial: ctId,
        ID_Ligue: ligueId,
        ID_Departement: this.item.ID_Departement,
        ID_Region: departement.region.id,
        EST_SectionAssociation: this.item.EST_SectionAssociation,
        ADR_Adresse_Correspondance: {
          data: {
            Adresse1: this.ADR_Adresse.Adresse1,
            Adresse2: this.ADR_Adresse.Adresse2,
            Ville: this.ADR_Adresse.Ville,
            CodePostal: this.ADR_Adresse.CodePostal,
            ID_Pays: this.ADR_Adresse.ID_Pays,
            ID_Structure: +lastStructure?.id + 1,
            NomAdresse: 'Adresse de correspondance',
          },
        },

        ACTI_ActivitePratiquees: {
          data: activitePratique,
        },
      };

      if (this.item.ID_TypeStructure === 4) {
        // CT
        delete payloadStructure.ID_Ligue;
      }
      if (this.item.ID_TypeStructure === 5) {
        // Ligue
        delete payloadStructure.ID_Ligue;
        delete payloadStructure.ID_ComiteTerritorial;
      }

      console.log("payloadStructure", payloadStructure);

      let structureID = await this.createNewStructure({
        structure: payloadStructure,
      });

      //Link the users to the structure and add the fonction id (president, secretaire, tresorier)
      let payloadUsers = [];
      for (const [key, value] of Object.entries(users)) {
        let fonctionID = null;
        if (key == "ID_President")
          fonctionID = this.allFonctionType.find(
            (f) => f.SlugFonction == "president"
          ).id;
        if (key == "ID_Secretaire")
          fonctionID = this.allFonctionType.find(
            (f) => f.SlugFonction == "bureau_secretaire"
          ).id;
        if (key == "ID_Tresorier")
          fonctionID = this.allFonctionType.find(
            (f) => f.SlugFonction == "bureau_tresorier"
          ).id;

        if (Object.keys(value).length != 0) {
          payloadUsers.push({
            ID_Utilisateur: value,
            ID_Structure: structureID,
            EST_Actif: true,
            EST_RoleFederal: true,
            EST_StructureLicencie: false,
            ID_Fonction: fonctionID,
            ID_Role: 5, //roile amdinistrateur club
          });
        }
      }

      console.log("payloadUsers", payloadUsers);

      await this.addUserToStructureOnPrimoAffi({ user: payloadUsers });

      // return the structure id
      return structureID;
    },

    // Generate the array of activities
    makeActivitesPratiques() {
      let activites = [];
      if (this.item.ID_TypeStructure != 6) {
        this.ACTI_ActivitePratiquees.forEach((acti) => {
          activites.push({
            ID_Activite: acti.ID_Activite,
            Annee: this.$moment().format("YYYY-MM-DD"),
            EST_ActivitePrincipale: acti.EST_ActivitePrincipale,
          });
        });
      }
      return activites;
    },

    // Check if the user already exist
    async checkUser(user) {
      // Check user correspondant
      // Check the user on the couple firstname lastname and email
      const licenceNumber = user.LicenceNumero
          ? user.LicenceNumero
          : user.representantLicenceNumero;

      let userResponseNew = await getPrimoAffiIsExistingUser({
        "lastname": user.CT_Nom
          ? trimAndUppercaseAllLetters(user.CT_Nom)
          : trimAndUppercaseAllLetters(user.representantLastName),
        "firstname": user.CT_Prenom
          ? trimAndCapitalizeFirstLetter(user.CT_Prenom)
          : trimAndCapitalizeFirstLetter(user.representantFirstName),
        "email": user.CT_Email
          ? user.CT_Email.toLowerCase()
          : user.representantEmail.toLowerCase(),
        "licenceNumber": licenceNumber ?? null,
      })

      // If yes, retreive and return the id
      if (userResponseNew && userResponseNew[0]) {
        return userResponseNew[0].id;
      }

      // If not, create it and retreive the id
      return await this.createUser(user);
    },

    // Create a user in cognito
    async createUser(user) {
      let newUserID = await this.createAuthUser({
        user: user, // email, username, firstname, lastname
        // ID_Admin: this.currentUser.id,
        ID_Admin: "0c3d061f-d338-4676-856e-62d18924ea62",
        ID_Structure: this.currentStructure.ID_Structure,
      });
      const currentLicenceNumero = await this.getUtilisateur({ ID_Utilisateur: newUserID })
      const userPayload = {
        CT_Nom: user.CT_Nom,
        CT_Prenom: user.CT_Prenom,
        CT_Email: user.CT_Email,
        id: newUserID,
        LicenceNumero: currentLicenceNumero?.LicenceNumero,
      };
      await this.updateUser({ user: { ...userPayload } });

      return newUserID;
    },

    generateDocumentData(doc, structureID, needId) {
      console.log("generateDocumentData", doc);
      if (needId) {
        return {
          ID_Structure: structureID,
          CheminDocument: doc ? doc.CheminDocument : "",
          NomDocument: doc ? doc.NomDocument : "",
          DateFinValiditeDocument: doc ? doc.DateFinValiditeDocument : null,
          EST_Actif: true,
          ID_Type_Document: doc ? doc.ID_DocumentType : null,
          ID_Saison: this.STR_Affiliation
            ? this.STR_Affiliation.ID_Premiere_Saison
            : this.SeasonId,
          EST_DocumentValide: true,
          id: doc ? doc.id : null,
        };
      }
      return {
        ID_Structure: structureID,
        CheminDocument: doc ? doc.CheminDocument : "",
        NomDocument: doc ? doc.NomDocument : "",
        DateFinValiditeDocument: doc ? doc.DateFinValiditeDocument : null,
        EST_Actif: true,
        ID_Type_Document: doc ? doc.ID_DocumentType : null,
        ID_Saison: this.STR_Affiliation
          ? this.STR_Affiliation.ID_Premiere_Saison
          : this.SeasonId,
        EST_DocumentValide: true,
      };
    },

    // Insert the affiliation object with the docs
    async insertAffiliation(structureID) {
      let affiliation = this.STR_Affiliation;

      // if (this.STR_Affiliation.ID_CollecteurFormationProfessionnelle) {
      //   this.STR_Affiliation.ID_CollecteurFormationProfessionnelle =
      //     this.STR_Affiliation.ID_CollecteurFormationProfessionnelle.id;
      // }

      let payload = {
        ID_EtapeAffiliation: 3,
        ID_Structure: structureID,
        ID_Saison: this.STR_Affiliation
          ? this.STR_Affiliation.ID_Premiere_Saison
          : this.SeasonId,
        ID_CollecteurFormationProfessionnelle: this.STR_Affiliation
          .ID_CollecteurFormationProfessionnelle,
        ...affiliation,
      };
      console.log("payload 1111", payload);

      await insertStructureDocumentFromDocDocumentUrl(
        this.DOC_Sepa.CheminDocument,
        structureID,
        this.SeasonId,
        'sepa_mandate',
      );

      await insertStructureDocumentFromDocDocumentUrl(
        this.DOC_Status.CheminDocument,
        structureID,
        this.SeasonId,
        'status',
      );

      await insertStructureDocumentFromDocDocumentUrl(
        this.DOC_Rib.CheminDocument,
        structureID,
        this.SeasonId,
        'rib',
      );

      //Insert RIB
      await this.insertMKPRib(structureID);

      delete payload.ID_Premiere_Saison;

      if (
        this.item.ID_TypeStructure === 1 && !this.item.EST_SectionAssociation // association
      ) {
        await insertStructureDocumentFromDocDocumentUrl(
          this.DOC_ParutionJO.CheminDocument,
          structureID,
          this.SeasonId,
          'jo_publication',
        );

        await insertStructureDocumentFromDocDocumentUrl(
          this.DOC_RecepissePrefectoralDeclaration.CheminDocument,
          structureID,
          this.SeasonId,
          'recepisse_prefectoral_declaration',
        );
      }

      if (
        this.item.ID_TypeStructure === 1 && this.item.EST_SectionAssociation // section association
      ) {
        await insertStructureDocumentFromDocDocumentUrl(
          this.DOC_ParutionJO.CheminDocument,
          structureID,
          this.SeasonId,
          'jo_publication',
        );

        await insertStructureDocumentFromDocDocumentUrl(
          this.DOC_RecepissePrefectoralDeclaration.CheminDocument,
          structureID,
          this.SeasonId,
          'recepisse_prefectoral_declaration',
        );

        await insertStructureDocumentFromDocDocumentUrl(
          this.DOC_ReglementInterieur.CheminDocument,
          structureID,
          this.SeasonId,
          'internal_rules',
        );
      }
      if (
        this.item.ID_TypeStructure === 3 // établissement affilié
      ) {
        await insertStructureDocumentFromDocDocumentUrl(
          this.DOC_DiplomeEncadrant.CheminDocument,
          structureID,
          this.SeasonId,
          'supervisor_diploma',
        );

        await insertStructureDocumentFromDocDocumentUrl(
          this.DOC_CarteProfessionnelle.CheminDocument,
          structureID,
          this.SeasonId,
          'professional_card',
        );

        await insertStructureDocumentFromDocDocumentUrl(
          this.DOC_AttestationAssurance.CheminDocument,
          structureID,
          this.SeasonId,
          'insurance_certificate',
        );
      }
      console.log("payload affiliationResponse", payload);

      let affiliationResponse = await this.insertPrimoAffiliation({
        affiliation: payload,
      });
      return affiliationResponse.id;
    },

    // Insert the tarif of the strucutre
    //a revoir ty tsy mampandeha le code
    // async insertTarifs(structureID) {
    //   let familleTarif = await this.insertNewFamilleTarif({
    //     tarif: {
    //       ID_Structure: structureID,
    //       NomFamilleTarif: 'Tarif ' + this.item.STR_NomStructure,
    //       SlugFamilleTarif:
    //         'tarif_' +
    //         this.item.STR_NomStructure.toLowerCase().replace(' ', '_'),
    //       ID_TarifCategorie: this.categoryTarifFamille.find(
    //         (famille) => famille.SlugCategorieTarif == 'licence_club'
    //       ).id,
    //     },
    //   });

    //   let payload = [];
    //   this.MKP_Tarifs.forEach((tarif) => {
    //     payload.push({
    //       ID_Saison: this.STR_Affiliation.ID_Premiere_Saison,
    //       ID_Structure: structureID,
    //       MontantUnitaire: tarif.MontantTarif,
    //       NomTarif: tarif.NomTarif,
    //       ID_EtapeValidationTarif: 3,
    //       EST_Actif: true,
    //       ID_FamilleTarif: familleTarif.insert_MKP_TarifFamille.returning[0].id,
    //     });
    //   });

    //   let tarifs = await this.updateAffiliationTarifSructure({
    //     tarif: payload,
    //   });

    //   return tarifs.data.insert_MKP_Tarif.returning;
    // },

    // Generate and insert the flux
    //le getTarifCotisationFFME ko null d tsy mande le code
    async generateFlux(structureID) {
      // let fluxFFME = await this.getTarifCotisationFFME({
      //   ID_Saison: this.STR_Affiliation.ID_Premiere_Saison,
      // });

      let fluxStructure = {
        ID_FluxFinancierDebit: 0,
        ID_Saison: this.STR_Affiliation
          ? this.STR_Affiliation.ID_Premiere_Saison
          : this.SeasonId,
        ID_Structure: structureID,
        ID_Traitement: 0,
        ID_Utilisateur: null,
        MontantHT: 125, //fluxFFME.MontantUnitaire
        MontantTTC: 125, //fluxFFME.MontantUnitaire
        MontantTVA: 1,
        NomFluxFinancier:
          "COTISATION AFFILIATION " + this.item.STR_NomStructure,
        EST_Reglement: false,
        MKP_FluxFinancierDetail: {
          data: [
            {
              ID_Structure: structureID,
              ID_Utilisateur: null,
              ID_Saison: this.STR_Affiliation
                ? this.STR_Affiliation.ID_Premiere_Saison
                : this.SeasonId,
              ID_Tarif: 11, //fluxFFME.id
              MontantHT: 125, //fluxFFME.MontantUnitaire
              MontantTTC: 125, //fluxFFME.MontantUnitaire
              MontantTVA: 1,
              FIN_CodeAnalytique: 0,
              FIN_CodeCompta: 0,
              NomFluxFinancierDetail:
                "COTISATION AFFILIATION " + this.item.STR_NomStructure,
            },
          ],
          on_conflict: {
            constraint: "MKP_FluxFinancierDetail_pkey",
            update_columns: ["ID_Tarif"],
          },
        },
      };

      let flux = await this.createFluxFinancierDetail({ flux: fluxStructure });
      // this.printFacture(flux);
    },
    // printFacture(item) {
    //   console.log("printFacture", item);
    //   this.$refs.facture.printFacture(item);
    // },
    async insertMKPRib(structureID) {
      let payload = {
        ID_Structure: structureID,
        FIN_IbanNumero: this.MKP_Rib.IBAN,
        FIN_BicNumero: this.MKP_Rib.BIC,
      };

      await this.insertRib(payload);
    },
  },

  props: {
    item: {
      type: Object,
    },
  },
};
</script>

<style>
.v-input__slot {
  max-width: 100% !important;
  padding: 0 !important;
}
</style>
