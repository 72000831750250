<template>
  <div>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle + ' ' + saisonSelected.label"
        route_name="gestion_des_licences__dashboard"
      />
    </v-row>

    <SaisirUneLicence
      :context="{
        ID_Structure: currentStructureId,
        ID_Saison: saisonSelected.id,
        hasCodeFederal
      }"
    />
  </div>
</template>

<script>
import SubBar from "@/components/Common/Common__SubBar.vue";
import SaisirUneLicence from "@/components/User/Licence/User__SaisirUneLicence.vue";
import { mapGetters } from "vuex";

export default {
  name: "GestionDesLicences__SaisirUneLicence",

  components: { SubBar, SaisirUneLicence },

  data: () => ({
    subBarTitle: "Saisir une licence",
    mounted: false,
    saisonSelected: null,
    e1: 1,
  }),

  created() {
    this.saisonSelected = this.selectedSaison;
    this.mounted = true;
  },
  computed: {
    ...mapGetters("structure", ["currentStructureId", "hasCodeFederal"]),
    ...mapGetters("saisons", ["selectedSaison", "saisons"]),
  },
};
</script>
